import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { PostsExcert } from '../components/post-excerpt'
import { Post, PageProps } from '../types'

type DataProps = PageProps<{
  allMarkdownRemark: {
    nodes: Post[]
  }
}>

const BlogIndex: React.FC<DataProps> = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes
  return (
    <Layout
      pages={data.pages}
      location={location}
      site={data.site}
      title="Latest Stories">
      <SEO title="All posts" />
      {(posts.length && <PostsExcert posts={posts} site={data.site} />) || (
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      )}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author {
          name
          summary
        }
      }
    }
    pages: allFile(
      filter: {
        sourceInstanceName: { eq: "pages" }
        childMarkdownRemark: { id: { glob: "*" } }
      }
    ) {
      nodes {
        childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "//blog//" } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 350)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          categories
          title
          description
          tags
        }
      }
    }
  }
`
